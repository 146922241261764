import { Field } from "formik";
import React, { useState } from "react";
import Select from "react-select";
import AddIcon from "../../../../assets/images/add-icon.png";
import ButtonTransparent from "../../../../components/Buttons/ButtonTransparent";
import CustomAccordion from "../../../../components/Accordion/CustomAccordion";
import ChainIDModal from "./ChainIDModal";
import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";

export const ChainID = ({ values, setFieldValue, dsaFields, dsbFields }) => {
  const [showChainIDModal, setShowChainIDModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [chainIDObject, setChainIDObject] = useState(null);
  const [editObject, setEditObject] = useState(null);

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center">
        {/* Add Button to Add ChainID Fields */}
        <ButtonTransparent
          title={"Add Field"}
          icon={AddIcon}
          onClick={() => {
            setEditObject({
              path: `chainId.fields[${
                values?.chainId?.fields?.length > 0
                  ? values?.chainId?.fields?.length
                  : 0
              }]`,
              isNew: true,
            });
            setChainIDObject({
              label: "",
              ds_a_field_name: "",
              ds_b_field_name: "",
            });
            setShowChainIDModal(true);
          }}
          border
        />
      </div>
      {values?.chainId?.fields?.length > 0 ? (
        values?.chainId?.fields?.map((row, index) => (
          <CustomAccordion
          id={"chainID" + index}
          index={index}
            title={row?.label ?? ""}
            onRemove={() => {
              // remove current object
              setShowDeleteModal(true);
              setDeleteIndex(index);
            }}
            onEdit={() => {
              setChainIDObject(row);
              setEditObject({
                path: "chainId.fields[" + index + "]",
                isNew: false,
              });
              setShowChainIDModal(true);
            }}
            defaultActiveKey={false}
            showDragIcon={false}
            showEditIcon={true}
          >
            <fieldset disabled className="p-3 my-3">
              <div className="row">
                <div className="col-4">
                  {/* Label Text Field */}
                  <div className="workflowModalTitle">Label</div>
                  <Field
                    id={"label" + index}
                    title={row?.label}
                    className="form-control rs-input highlight"
                    type="text"
                    placeholder="Label"
                    name={`chainId.fields[${index}].label`}
                  />
                </div>
                <div className="col-4">
                  {/* DS A Field Name DropDown */}
                  <div className="workflowModalTitle">DSA Field Name</div>
                  <div title={row?.ds_a_field_name}>
                    <Select
                      id={"ds_a_field_name" + index}
                      classNamePrefix={"dsa-field-name" + index}
                      options={dsaFields}
                      name={"ds A Field Name"}
                      value={dsaFields?.find(
                        (field) => field.value == row?.ds_a_field_name
                      )}
                      components={{ DropdownIndicator: null }}
                    />
                  </div>
                </div>
                <div className="col-4">
                  {/* DS B Field Name DropDown */}
                  <div className="workflowModalTitle">DSB Field Name</div>
                  <div title={row?.ds_b_field_name}>
                    <Select
                      id={"ds_b_field_name" + index}
                      classNamePrefix={"dsb-field-name" + index}
                      options={dsbFields}
                      name={"ds B Field Name"}
                      value={dsbFields?.find(
                        (field) => field.value === row?.ds_b_field_name
                      )}
                      components={{ DropdownIndicator: null }}
                    />
                  </div>
                </div>
              </div>
            </fieldset>
          </CustomAccordion>
        ))
      ) : (
        <>
          <div className="text-center my-3">No Chain ID Found</div>
        </>
      )}
      {showChainIDModal ? (
        <ChainIDModal
          show={showChainIDModal}
          editObject={editObject}
          onHide={() => {
            setShowChainIDModal(false);
            setChainIDObject(null);
            setEditObject({ isNew: false, path: null });
          }}
          handleSubmit={(path, values) => {
            setFieldValue(path, values);
          }}
          dsaFields={dsaFields}
          dsbFields={dsbFields}
          values={chainIDObject}
        />
      ) : null}
      <ConfirmationModal
        isShow={showDeleteModal}
        title={`Delete Chain ID Field`}
        message={`Are you sure you want to delete this Chain ID Field?`}
        hideModal={() => {
          setShowDeleteModal(false);
        }}
        confirmAction={() => {
          values.chainId.fields.splice(deleteIndex, 1);
          setFieldValue("chainId.fields", [...values.chainId.fields]);
          setDeleteIndex(null);
          setShowDeleteModal(false);
        }}
      />
    </div>
  );
};
