import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router";
import axiosInstance from "../../utils/axiosInstance";
import Toast from "../../components/Toast/Toast";
import logo from "../../assets/images/logo-sm.svg";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import DibLogo from "../../assets/images/logos/dibLogo.png";
import McbLogo from "../../assets/images/logos/mcb-logo.svg";
const resetPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

const ResetPassword = () => {
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const forgotPassword = async (values) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post("/users/forgot-password", {
        data: { username: values.email },
      });
      if (response.message.status === "200") {
        setLoading(false);
        setSuccessMessage(response.message.description);
      } else {
        Toast(response.message.message, "error");
        setErrorMessage(true);
        setLoading(false);
      }
    } catch (err) {
      setErrorMessage(true);
      setLoading(false);
    }
  };

  // Get the logoType from localStorage, fallback to the state
  const logoTypeFromLocalStorage = localStorage.getItem("landingPageLogo");

  return (
    <>
      {loading && (
        <div
          className="spinner-center"
          style={{ top: "0%", left: "0%", zIndex: 2000 }}
        >
          <LoaderComponent />
        </div>
      )}

      <div className="auth-container">
        <div className="d-flex justify-content-center align-items-center brand-name-wrapper pb-3">
          <img
            src={
              logoTypeFromLocalStorage === "dib"
                ? DibLogo
                : logoTypeFromLocalStorage === "MCB"
                ? McbLogo
                : logo
            }
            onClick={() => this.props.history.push("/login")}
            alt="Logo"
            width={"10%"}
          />
        </div>
        <div id="login">
          <div className="card auth-card">
            <div className="card-body">
              {successMessage ? (
                <>
                  <p style={{ fontSize: "20px" }}>
                    {/* Thank you for requesting a password reset. We have sent an
                    email to the address associated with your account with
                    instructions on how to reset your password. Please check
                    your inbox and follow the link provided to reset your
                    password. If you don't receive the email within a few
                    minutes, please check your spam folder, try again or contact
                    our support team for further assistance. */}
                    {successMessage}
                  </p>
                  <div className="form-group">
                    <br />
                    <Link to="/login" id="register-link">
                      <p className="text-center text-info text-underline">
                        Back To Login
                      </p>
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <h3 className="ml-4 text-dark pt-5 font-weight-bold">
                    Forgot Password?
                  </h3>
                  <div className="container mt-5">
                    <div
                      id="login-row"
                      className="row justify-content-center align-items-center"
                    >
                      <div id="login-column" className="col-md-12">
                        <div id="login-box" className="col-md-12">
                          <p>
                            Don’t worry! Fill in your email and we’ll send you a
                            link to reset your password.
                          </p>
                          <Formik
                            initialValues={{ email: "" }}
                            validationSchema={resetPasswordSchema}
                            onSubmit={(values) => {
                              forgotPassword(values);
                            }}
                          >
                            <Form id="login-form" className="form">
                              <div className="form-group">
                                <label htmlFor="username" className="text-info">
                                  Email:
                                </label>
                                <br />
                                <Field
                                  type="email"
                                  name="email"
                                  id="email"
                                  className="form-control rs-input"
                                  placeholder="e.g example@gmail.com"
                                  style={{ borderRadius: "10px" }}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="email"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="form-group">
                                <br />
                                <input
                                  id="submit"
                                  type="submit"
                                  name="submit"
                                  className={"btn btn-info btn-md"}
                                  style={{ width: "100%" }}
                                  value="Send Email"
                                />
                              </div>
                              {errorMessage && (
                                <div
                                  className="alert alert-danger"
                                  role="alert"
                                >
                                  No user associated with that email address
                                </div>
                              )}

                              <div className="form-group">
                                <br />
                                <Link to="/login" id="register-link">
                                  <p className="text-center text-info text-underline">
                                    Back To Login
                                  </p>
                                </Link>
                              </div>
                            </Form>
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ResetPassword);
