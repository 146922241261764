import React from "react";
import ButtonBasic from "../../../components/Buttons/ButtonBasic";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import PairExMatchingRule from "./PairExMatchingRule";
import { Tab, Tabs } from "react-bootstrap";
import Toast from "../../../components/Toast/Toast";

const PairExecutionModal = ({
  setShowPairModal,
  addExecutionPair,
  matching,
  dsaFields,
  dsbFields,
  operatorOptions,
}) => {
  const formValues = {
    ruleName: "",
    ruleType: "",
    matchingBucket: "",
    matchingRule: {
      fields: [
        {
          ds_a_field_name: "",
          ds_b_field_name: "",
          operator: "",
          label: "",
        },
      ],
    },
    matchingScript: "",
    matchingType: "rule",
    sequence: "",
    enabled: true,
  };

  const ruleTypeOptions = (value) => {
    if (value === "1:1") {
      return [{ value: "1:1", label: "1:1" }];
    } else if (value === "1:M") {
      return [
        { value: "1:1", label: "1:1" },
        { value: "1:M", label: "1:M" },
      ];
    } else if (value === "M:1") {
      return [
        { value: "1:1", label: "1:1" },
        { value: "1:M", label: "1:M" },
        { value: "M:1", label: "M:1" },
      ];
    } else if (value === "M:M") {
      return [
        { value: "1:1", label: "1:1" },
        { value: "1:M", label: "1:M" },
        { value: "M:1", label: "M:1" },
        { value: "M:M", label: "M:M" },
      ];
    } else if (value === "1:MAG") {
      return [
        { value: "1:1", label: "1:1" },
        { value: "1:M", label: "1:M" },
      ];
    } else {
      return [];
    }
  };

  const matchingBucketOptions = [
    { value: "MATCHED", label: "MATCHED" },
    { value: "SUGGESTED", label: "SUGGESTED" },
  ];
  const addValidationSchema = Yup.object().shape({
    ruleName: Yup.string().required("Rule Name is required"),
    ruleType: Yup.string().required("Rule Type is required"),
    matchingBucket: Yup.string().required("Matching Bucket is required"),
    matchingType: Yup.string().required("Matching Type is required"),
    matchingRule: Yup.object()
      .nullable()
      .when("matchingType", {
        is: "rule",
        then: Yup.object().shape({
          fields: Yup.array().of(
            Yup.object().shape({
              ds_a_field_name: Yup.string().when("operator", {
                is: "IGNORE",
                then: Yup.string().optional(),
                otherwise: Yup.string().required("DSA Field Name is required"),
              }),
              ds_b_field_name: Yup.string().when("operator", {
                is: "IGNORE",
                then: Yup.string().optional(),
                otherwise: Yup.string().required("DSB Field Name is required"),
              }),
              operator: Yup.string().required("Operator is required"),
              regex: Yup.string()
                .optional()
                .when("operator", {
                  is: "REGEX-MATCH",
                  then: Yup.string().required("Regex is required"),
                }),
            })
          ),
        }),
      }),
    matchingScript: Yup.string().when("matchingType", {
      is: "script",
      then: Yup.string().required("Matching Script is required"),
    }),
  });
  return (
    <>
      <Formik
        initialValues={formValues}
        enableReinitialize={true}
        validationSchema={addValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (values?.matchingType === "rule") {
            for (let i = 0; i < values?.matchingRule?.fields?.length; i++) {
              if (
                values.matchingRule.fields[i]?.operator === "IGNORE" &&
                !values.matchingRule.fields[i]?.ds_a_field_name &&
                !values.matchingRule.fields[i]?.ds_b_field_name
              ) {
                Toast(
                  "Please select a value for either the DSA Field Name or the DSB Field Name when the operator is set to 'IGNORE'",
                  "error"
                );
                return;
              }
              if (
                values.matchingRule.fields[i]?.operator
                  ?.split("(")?.[0]
                  ?.includes("FUZZY") &&
                !values.matchingRule.fields[i]?.operator?.match(
                  /\(([^)]+)\)/
                )?.[1]
              ) {
                Toast(
                  "Matching % is mandatory for " +
                    operatorOptions?.matchingRuleOperators?.find(
                      (operator) =>
                        operator.value ===
                        values.matchingRule.fields[i]?.operator?.split("(")?.[0]
                    )?.label +
                    " operator",
                  "error"
                );
                return;
              }
              if (
                values.matchingRule.fields[i]?.hasOwnProperty(
                  "ds_a_field_func"
                ) &&
                !values.matchingRule.fields[i]?.ds_a_field_func
              ) {
                Toast("DSA Field Function is mandatory", "error");
                return;
              } else if (
                values.matchingRule.fields[i]?.ds_a_field_func?.split(
                  "("
                )[0] === "VAR"
              ) {
                if (
                  values.matchingRule.fields[i]?.ds_a_field_func?.includes("%")
                ) {
                  if (
                    !values.matchingRule.fields[i].ds_a_field_func?.match(
                      /-([\d.]+)%/
                    )?.[1] ||
                    !values.matchingRule.fields[i].ds_a_field_func?.match(
                      /\+([\d.]+)%/
                    )?.[1]
                  ) {
                    Toast("Minimum and Maximum fields are mandatory", "error");
                    return;
                  }
                }
                if (
                  !values.matchingRule.fields[i].ds_a_field_func?.match(
                    /(?<=-)[\d.]+/
                  )?.[0] ||
                  !values.matchingRule.fields[i].ds_a_field_func?.match(
                    /(?<=\+)[\d.]+/
                  )?.[0]
                ) {
                  Toast("Minimum and Maximum fields are mandatory", "error");
                  return;
                }
              } else if (
                values.matchingRule.fields[i]?.ds_a_field_func?.split(
                  "("
                )[0] === "LEFT"
              ) {
                if (
                  !values.matchingRule.fields[i].ds_a_field_func?.match(
                    /(?<=\().*?(?=\))/
                  )?.[0]
                ) {
                  Toast("No. of characters are mandatory", "error");
                  return;
                }
              } else if (
                values.matchingRule.fields[i]?.ds_a_field_func?.split(
                  "("
                )[0] === "RIGHT"
              ) {
                if (
                  !values.matchingRule.fields[i].ds_a_field_func?.match(
                    /(?<=\().*?(?=\))/
                  )?.[0]
                ) {
                  Toast("No. of characters are mandatory", "error");
                  return;
                }
              } else if (
                values.matchingRule.fields[i]?.ds_a_field_func?.split(
                  "("
                )[0] === "SUBSTR"
              ) {
                if (
                  !values.matchingRule.fields[i].ds_a_field_func?.match(
                    /(?<=\().*?(?=:)/
                  )?.[0] ||
                  !values.matchingRule.fields[i].ds_a_field_func?.match(
                    /(?<=:).*?(?=\))/
                  )?.[0]
                ) {
                  Toast("Start and End Characters are mandatory", "error");
                  return;
                }
              } else if (
                values.matchingRule.fields[i]?.ds_a_field_func?.split(
                  "("
                )[0] === "REMOVE-SPECIAL-CHAR"
              ) {
                if (
                  !values.matchingRule.fields[i].ds_a_field_func?.match(
                    /(?<=\().*?(?=\))/
                  )?.[0]
                ) {
                  Toast("Regex are mandatory", "error");
                  return;
                }
              }
              if (
                values.matchingRule.fields[i]?.hasOwnProperty(
                  "ds_b_field_func"
                ) &&
                !values.matchingRule.fields[i]?.ds_b_field_func
              ) {
                Toast("DSB Field Function is mandatory", "error");
                return;
              } else if (
                values.matchingRule.fields[i]?.ds_b_field_func?.split(
                  "("
                )[0] === "VAR"
              ) {
                if (
                  values.matchingRule.fields[i]?.ds_b_field_func?.includes("%")
                ) {
                  if (
                    !values.matchingRule.fields[i].ds_b_field_func?.match(
                      /-([\d.]+)%/
                    )?.[1] ||
                    !values.matchingRule.fields[i].ds_b_field_func?.match(
                      /\+([\d.]+)%/
                    )?.[1]
                  ) {
                    Toast("Minimum and Maximum fields are mandatory", "error");
                    return;
                  }
                }
                if (
                  !values.matchingRule.fields[i].ds_b_field_func?.match(
                    /(?<=-)[\d.]+/
                  )?.[0] ||
                  !values.matchingRule.fields[i].ds_b_field_func?.match(
                    /(?<=\+)[\d.]+/
                  )?.[0]
                ) {
                  Toast("Minimum and Maximum fields are mandatory", "error");
                  return;
                }
              } else if (
                values.matchingRule.fields[i]?.ds_b_field_func?.split(
                  "("
                )[0] === "LEFT"
              ) {
                if (
                  !values.matchingRule.fields[i].ds_b_field_func?.match(
                    /(?<=\().*?(?=\))/
                  )?.[0]
                ) {
                  Toast("No. of characters are mandatory", "error");
                  return;
                }
              } else if (
                values.matchingRule.fields[i]?.ds_b_field_func?.split(
                  "("
                )[0] === "RIGHT"
              ) {
                if (
                  !values.matchingRule.fields[i].ds_b_field_func?.match(
                    /(?<=\().*?(?=\))/
                  )?.[0]
                ) {
                  Toast("No. of characters are mandatory", "error");
                  return;
                }
              } else if (
                values.matchingRule.fields[i]?.ds_b_field_func?.split(
                  "("
                )[0] === "SUBSTR"
              ) {
                if (
                  !values.matchingRule.fields[i].ds_b_field_func?.match(
                    /(?<=\().*?(?=:)/
                  )?.[0] ||
                  !values.matchingRule.fields[i].ds_b_field_func?.match(
                    /(?<=:).*?(?=\))/
                  )?.[0]
                ) {
                  Toast("Start and End Characters are mandatory", "error");
                  return;
                }
              } else if (
                values.matchingRule.fields[i]?.ds_b_field_func?.split(
                  "("
                )[0] === "REMOVE-SPECIAL-CHAR"
              ) {
                if (
                  !values.matchingRule.fields[i].ds_b_field_func?.match(
                    /(?<=\().*?(?=\))/
                  )?.[0]
                ) {
                  Toast("Regex are mandatory", "error");
                  return;
                }
              }
            }
            //exlude the empty fields from values.matchingRule.fields each object
            values.matchingRule.fields = values.matchingRule.fields.map(
              (item) =>
                Object.fromEntries(
                  Object.entries(item).filter(([, v]) => v !== "")
                )
            );
          }
          setSubmitting(true);
          addExecutionPair(values);
          setShowPairModal(false);
        }}
      >
        {({ values, errors, setFieldValue, handleSubmit }) => (
          <Form
            id="add-execution-pair"
            className="form"
            onSubmit={handleSubmit}
          >
            <div>
              <div className="row">
                <div className="col-6">
                  <div className="workflowModalTitle">Rule Name</div>
                  <Field
                    id="ruleName"
                    className="form-control rs-input highlight"
                    type="text"
                    placeholder="Rule Name"
                    name={`ruleName`}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    component={() => (
                      <div className="field-error">{errors.ruleName}</div>
                    )}
                    name="ruleName"
                  />
                </div>
                <div className="col-6">
                  <div className="workflowModalTitle">Rule Type</div>
                  <Select
                    id="ruleType"
                    classNamePrefix={"ruleType"}
                    options={ruleTypeOptions(matching)}
                    name={"Rule Type"}
                    value={
                      ruleTypeOptions(matching)
                        ? ruleTypeOptions(matching).find(
                            (option) => option.value === values.ruleType
                          )
                        : ""
                    }
                    onChange={(e) => setFieldValue("ruleType", e.value)}
                    placeholder={"Select"}
                  />
                  <ErrorMessage
                    component={() => (
                      <div className="field-error">{errors.ruleType}</div>
                    )}
                    name="ruleType"
                  />
                </div>
                <div className="col-6 mt-3">
                  <div className="workflowModalTitle">Matching Bucket</div>
                  <Select
                    id="matchingBucket"
                    classNamePrefix={"matchingBucket"}
                    options={matchingBucketOptions}
                    name={"Matching Bucket"}
                    value={
                      matchingBucketOptions
                        ? matchingBucketOptions.find(
                            (option) => option.value === values?.matchingBucket
                          )
                        : ""
                    }
                    onChange={(e) => setFieldValue(`matchingBucket`, e.value)}
                    placeholder={"Select"}
                  />
                  <ErrorMessage
                    component={() => (
                      <div className="field-error">{errors.matchingBucket}</div>
                    )}
                    name="matchingBucket"
                  />
                </div>
                <div className="col-2 mt-3">
                  <div className="workflowModalTitle">Enabled</div>
                  <div className="form-check">
                    <Field
                      id="enabledTrue"
                      className="form-check-input"
                      type="radio"
                      name={`enabled`}
                      value="true"
                      checked={
                        values?.enabled === true || values?.enabled === "true"
                      }
                    />
                    <label className="form-check-label">True</label>
                  </div>
                  <div className="form-check">
                    <Field
                      id="enabledFalse"
                      className="form-check-input"
                      type="radio"
                      name={`enabled`}
                      value="false"
                      checked={
                        values?.enabled === false || values?.enabled === "false"
                      }
                    />
                    <label className="form-check-label">False</label>
                  </div>
                </div>
                <div className="col-12 p-3">
                  <Tabs
                    id="matching-rule-tab"
                    activeKey={values.matchingType}
                    onSelect={(k) => {
                      if (k === "script") {
                        setFieldValue(`matchingRule`, null);
                      } else if (k === "rule") {
                        setFieldValue(`matchingScript`, "");
                      }
                      setFieldValue(`matchingType`, k);
                    }}
                  >
                    <Tab eventKey="rule" title="Matching Rule">
                      <PairExMatchingRule
                        values={values}
                        setFieldValue={setFieldValue}
                        dsaFields={dsaFields}
                        dsbFields={dsbFields}
                        operatorOptions={operatorOptions}
                        errors={errors}
                      />
                      <ErrorMessage
                        component={() => (
                          <div className="field-error">
                            {errors.matchingBucket}
                          </div>
                        )}
                        name="Matching Rule is required"
                      />
                    </Tab>

                    <Tab eventKey="script" title="Matching Script">
                      <>
                        <Field
                          id="matchingScript"
                          className={`form-control rs-input`}
                          component="textarea"
                          rows="6"
                          placeholder="Dynamic"
                          name={`matchingScript`}
                        />
                        <ErrorMessage
                          component={() => (
                            <div className="field-error">
                              {errors.matchingScript}
                            </div>
                          )}
                          name="matchingScript"
                        />
                      </>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-4">
              <ButtonBasic
                id="cancelBtn"
                title="Cancel"
                onClick={() => setShowPairModal(false)}
              />
              <ButtonBasic id="addBtn" title="Add" onClick={handleSubmit} />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default PairExecutionModal;
