import React from "react";
import { Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import { ArrowRightIcon } from "../../assets/images";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ButtonBasic from "../../components/Buttons/ButtonBasic";
import CardSm from "../../components/CardSm/CardSm";
import FilterIcon from "../../assets/images/filter.png";
import axiosInstance from "../../utils/axiosInstance";
import { TransactionsStyle } from "./TransactionsStyle";
import moment from "moment";
import Toast from "../../components/Toast/Toast";
import LoaderComponent from "../../components/Spinner/LoaderComponent";
import queryString from "query-string";
import Select from "react-select";
import Filter from "../../components/FilterDropdown/GenericFilter";
import withDateTime from "../../utils/timedatehoc";
import CurrencyFormat from "react-currency-format";
import paginationFactory from "react-bootstrap-table2-paginator";
import { CustomTooltip } from "../../utils/helpers/custom-tooltip";
import TransactionlogModal from "./TransactionlogModal";
import {
  filtersParams,
  mapFilters,
  handleFilterChange,
} from "../../utils/filtersHelper";
import { ContextState } from "../../components/Helper/DateTime";
class Matching extends React.Component {
  static contextType = ContextState;
  constructor(props) {
    super(props);
    this.createTables = this.createTables.bind(this);
    this.manualReconAccounts = this.manualReconAccounts.bind(this);
    this.pagination = {
      onPageChange: this.onPageChanges.bind(this),
      onSizePerPageChange: this.onSizePerPageChange.bind(this),
      pageStartIndex: 1, // where to start counting the pages
      totalSize: 0,
      lastPageText: ">>",
      firstPageText: "<<",
      nextPageText: ">",
      prePageText: "<",
      hideSizePerPage: true,
      showTotal: false,
      alwaysShowAllBtns: true,
      paginationShowsTotal: false,
      sizePerPage: 10,
      page: 1,
    };
    this.modalPagination = {
      pageStartIndex: 1, // where to start counting the pages
      totalSize: 0,
      lastPageText: ">>",
      firstPageText: "<<",
      nextPageText: ">",
      prePageText: "<",
      hideSizePerPage: true,
      showTotal: false,
      paginationShowsTotal: false,
      sizePerPage: 10,
      page: 1,
    };
    this.modalAdjustmentPagination = {
      pageStartIndex: 1, // where to start counting the pages
      totalSize: 0,
      lastPageText: ">>",
      firstPageText: "<<",
      nextPageText: ">",
      prePageText: "<",
      hideSizePerPage: true,
      showTotal: false,
      paginationShowsTotal: false,
      sizePerPage: 10,
      page: 1,
    };
    this.state = {
      matchModalShow: false,
      showTLModal: false,
      aggregateTxnId: null,
      apiData: [],
      SplitModalShow: false,
      manualMatchingData: [],
      transactionData: [],
      matchingData: [],
      isSelectedRowsDTSA: false,
      isSelectedRowsDTSB: false,
      loader: true,
      fromDatasourceId: undefined,
      toDatasourceId: undefined,
      breadCrumbState: [
        {
          id: 0,
          title: "Source Pairs",
          isActive: false,
          routeTo: "/matching-pairs",
        },
        {
          id: 1,
          title: "Manual Matching",
          isActive: true,
          routeTo: "/matching",
        },
      ],
      breadCrumbState2: [
        {
          id: 0,
          title: "Transaction Log",
          isActive: false,
          routeTo: "/transaction-log",
        },
        {
          id: 1,
          title: "Manual Matching",
          isActive: true,
          routeTo: "/matching",
        },
      ],
      useCaseImplied: "",
      notes: [],
      manualTransactionData: {},
      adjustmentEntries: [],
      datasourceA: "",
      datasourceB: "",
      creditAccounts: [],
      debitAccounts: [],
      searchedDebitAccounts: [],
      searchedCreditAccounts: [],

      //states for dropdowns in modal
      selectedCategory: null,
      selectedDebitAccount: null,
      selectedCreditAccount: null,
      categories: [],
      debitAccounts: [],
      creditAccounts: [],

      filters: {},
      filterData: [],
      filterLoader: true,
      adjustmentCategory: [],
      categories: [],
      selectedRows: [],
      selectedRowsAmount: 0,
      selectedRowsDTSA: [],
      selectedRowsDTSB: [],
      amountsDTSA: [],
      amountsDTSB: [],
      selectedAmountA: 0,
      selectedAmountB: 0,
      column1: [],
      column2: [],
      column1Adjustment: [],
      column2Adjustment: [],
      data1: [],
      data2: [],
      showDoneButton: false,
      dataSources: {},
      userName: `${JSON.parse(localStorage.getItem("userData")).firstName} ${
        JSON.parse(localStorage.getItem("userData")).lastName
      }`,
      userId: JSON.parse(localStorage.getItem("userData"))?.userId,
      limit: 10,
      noteFlag: false,
      adjustmentEntriesColumns: [],
      isProcessing: false,
    };
  }

  onPageChanges = (page, sizePerPage) => {
    this.setCurrentPage(page);
    this.setState({ loader: true }, () => {
      this.getManualMatchingData();
    });
  };

  onSizePerPageChange = (sizePerPage) => {
    this.setState({ loader: true }, () => {
      this.pagination = {
        ...this.pagination,
        sizePerPage: sizePerPage,
      };
      this.getManualMatchingData();
    });
  };

  setCurrentPage(page) {
    let _options = { ...this.pagination };
    _options["page"] = page;
    this.pagination = _options;
  }

  getData() {
    this.setState({ filterLoader: true }, () => {
      this.getFilters();
    });
  }
  getAdjustmentCategory = () => {
    axiosInstance
      .get("/common/code?codeTypeId=11")
      .then((response) => {
        this.setState({
          categories:
            response.data?.map((item) => ({
              value: item.codeValue,
              label: item.name,
            })) || [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleShowAdjustment = () => {
    const matchData = this.state.selectedRows?.map((row) => ({
      id: this.state.transactionData[row]?.id,
      status: this.state.transactionData[row]?.status,
    }));

    const requestData = {
      data: {
        matchingData: matchData,
        selectedDataSourceName: this.state.isSelectedRowsDTSA
          ? this.state.dataSources.datasourceAName
          : this.state.isSelectedRowsDTSB
          ? this.state.dataSources.datasourceBName
          : "",
        creditAccount: this.state.selectedCreditAccount,
        debitAccount: this.state.selectedDebitAccount,
        category: this.state.selectedCategory,
      },
    };
    axiosInstance
      .post("/matching/adjustment-entries", requestData)
      .then((res) => {
        if (res.message.status !== "200") {
          Toast(res.message.description, "error");
        } else {
          this.setState({
            ...this.state,
            adjustmentEntries: res?.data,
          });
        }
      })
      .catch((err) => {
        console.log("show adj error....", err);
      });
  };

  componentDidMount() {
    this.initData();
    this.setState({
      adjustmentEntriesColumns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true,
        },
        {
          dataField: "transactionDetails",
          text: "Transaction Details",
          formatter: (cell, row, rowIndex) => (
            <div>
              <BootstrapTable
                bordered={false}
                keyField="id"
                wrapperClasses="overflow-y-hidden overflow-x-hidden"
                classes="rs-table table-layout-auto mb-0 table-custom-style"
                columns={[
                  {
                    dataField: "glAccount",
                    text: "GL Account",
                    style: { minWidth: "100px" },
                    formatter: (cell) => (cell ? cell : "-"),
                  },
                  {
                    dataField: "postingDate",
                    text: "Posting Date",
                    formatter: (cell) => (cell ? cell : "-"),
                  },
                  {
                    dataField: "description",
                    text: "Description",
                    style: {
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      minWidth: "342px",
                      lineHeight: "1.5",
                    },
                    formatter: (cell) => (cell ? cell : "-"),
                  },
                  {
                    dataField: "creditAmount",
                    text: "Credit Amount",
                    formatter: (cell, row) => this.formatAmount(cell, row),
                  },
                  {
                    dataField: "debitAmount",
                    text: "Debit Amount",
                    formatter: (cell, row) => this.formatAmount(cell, row),
                  },
                  {
                    dataField: "entryReference",
                    text: "Entry Reference",
                    formatter: (cell) => (cell ? cell : "-"),
                  },
                ]}
                data={cell || []}
                headerClasses={rowIndex === 0 ? null : "visibility-collapse"}
              />
              {this.state.adjustmentEntries?.length - 1 !== rowIndex && <hr />}
            </div>
          ),
        },
      ],
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.useCaseImplied === "impacting") {
      if (
        (prevState.selectedCreditAccount !== this.state.selectedCreditAccount ||
          prevState.selectedDebitAccount !== this.state.selectedDebitAccount ||
          prevState.selectedCategory !== this.state.selectedCategory ||
          prevState.isSelectedRowsDTSA !== this.state.isSelectedRowsDTSA ||
          prevState.isSelectedRowsDTSB !== this.state.isSelectedRowsDTSB) &&
        (this.state.isSelectedRowsDTSA || this.state.isSelectedRowsDTSB)
      ) {
        this.handleShowAdjustment();
      }
    }
  }
  initData() {
    this.getFilters()
      .then(() => {
        this.props.dateTime.setRefresh(() => () => {
          this.setState({ loader: true }, () => {
            // Only call getManualMatchingData if needed
            if (!this.state.loader) {
              this.getManualMatchingData();
            }
          });
        });
      })
      .catch((error) => {
        console.error("Error initializing data:", error);
      });
  }

  componentWillUnmount() {
    this.props.dateTime.setRefresh(() => () => {});
  }

  formatAmount(cell, row) {
    return cell ? (
      <>
        <div className="d-flex justify-content-between">
          <span className="pr-3">{this.props?.dateTime?.currency}</span>
          <span>
            <CurrencyFormat
              value={cell ?? "-"}
              displayType={"text"}
              thousandSeparator={true}
            />
          </span>
        </div>
      </>
    ) : (
      "-"
    );
  }
  createTables() {
    let {
      column1,
      column2,
      column1Adjustment,
      column2Adjustment,
      data1,
      data2,
      matchingData,
    } = this.state;
    column1 = [];
    column2 = [];
    column1Adjustment = [];
    column2Adjustment = [];
    data1 = [];
    data2 = [];
    let { currency, dateTime } = this.props?.dateTime;

    matchingData.map((data, i) => {
      // eslint-disable-next-line
      data.map((column) => {
        if (column1.some((el) => el.dataField === column.datasourceA.key)) {
        } else {
          column1.push({
            dataField: column.datasourceA.key,
            text: column.label,
            formatter: function (cell, row) {
              if (column.label.toLowerCase().includes("amount")) {
                return (
                  <CurrencyFormat
                    value={cell ?? "-"}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={currency + " "}
                  />
                );
              } else {
                return <span>{cell ?? "-"}</span>;
              }
            },
          });
          column1Adjustment = column1;
        }
        data1[i] = {
          ...data1[i],
          id: i,
          tId: this?.state?.transactionData[i]?.id,
          aggregateTxnId: this?.state?.transactionData[i]?.aggregateTxnId,
          [column.datasourceA.key]: column.datasourceA.value,
        };
        if (column2.some((el) => el.dataField === column.datasourceB.key)) {
        } else {
          column2.push({
            dataField: column.datasourceB.key,
            text: column.label,
            formatter: (cell, row) => {
              if (column.label.toLowerCase().includes("amount")) {
                return (
                  <CurrencyFormat
                    value={cell ?? "-"}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={currency + " "}
                  />
                );
              } else {
                return <span>{cell ?? "-"}</span>;
              }
            },
          });
          column2Adjustment = column2;
        }
        data2[i] = {
          ...data2[i],
          id: i,
          tId: this?.state?.transactionData[i]?.id,
          aggregateTxnId: this?.state?.transactionData[i]?.aggregateTxnId,
          [column.datasourceB.key]: column.datasourceB.value,
        };
      });
      return "";
    });
    const { txnId } = queryString.parse(this.props.location.search);
    if (txnId) {
      const selectedRowsDTSA = data1
        .filter((item) => item.aggregateTxnId == txnId)
        .map((item) => item.id);

      const selectedRowsDTSB = data2
        .filter((item) => item.aggregateTxnId == txnId)
        .map((item) => item.id);
      const selectedRows = Array.from(
        new Set([...selectedRowsDTSA, ...selectedRowsDTSB])
      );
      this.setState({
        selectedRowsDTSA,
        selectedRowsDTSB,
        selectedRows,
      });
    }
    this.setState({
      column1,
      column2,
      column1Adjustment,
      column2Adjustment,
      data1,
      data2,
    });
  }

  async getManualMatchingData() {
    const { id, business_date, from, to, txnId } = queryString.parse(
      this.props.location.search
    );
    // const { limit } = this.state;
    const { page, sizePerPage } = this.pagination;

    const businessDateFilter = business_date
      ? `&business_date=${business_date}`
      : "";
    let allFilter = filtersParams(this.state.filters, businessDateFilter);
    try {
      let url = `/matching/datasource/${id}${allFilter ? "?" : ""}${
        txnId ? "&agg_txn_id=" + txnId : ""
      }${allFilter}&page=${page - 1}&size=${sizePerPage}`;
      let transactionData = [];
      let matchingData = [];
      let manualMatchingData = [];
      let selectedRowsDTSA = [];
      let selectedRowsDTSB = [];
      let amountsDTSA = [];
      let amountsDTSB = [];
      let selectedRows = [];
      let selectedAmountA = 0;
      let selectedAmountB = 0;
      const response = await axiosInstance.get(url);
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
        this.setState({ loader: false });
        this.props.history.push("/matching-pairs");
      } else {
        manualMatchingData = response.data;
        this.setState({
          ...this.state,
          transactionData: response.data?.transactions,
        });
        let datasourceA = manualMatchingData?.datasourceA;
        let datasourceB = manualMatchingData?.datasourceB;
        let datasourceAName = manualMatchingData?.datasourceAName;
        let datasourceBName = manualMatchingData?.datasourceBName;
        transactionData = response.data?.transactions?.map((transaction, i) => {
          let matching = transaction?.matchingData?.map((data) => {
            if (data.label.toLowerCase().includes("amount")) {
              amountsDTSA.push(data.datasourceA.value);
              amountsDTSB.push(data.datasourceB.value);
            }
            return data;
          });
          matchingData.push(matching);
          return (transaction = {
            manualMatch: transaction?.matchingData?.map((data) => {
              return data;
            }),
            id: transaction?.id,
            index: i,
            note: transaction?.notes,
            batchId: transaction?.batchId,
            status: transaction?.status,
            reconAccountId: transaction?.reconAccountId,
          });
        });

        this.pagination.totalSize = response?.page?.totalElements;
        this.setState(
          {
            allowAccountEntries: response?.data?.allowAccountEntries,
            matchingData,
            manualMatchingData,
            selectedRowsDTSA,
            selectedRowsDTSB,
            selectedRows,
            amountsDTSA,
            amountsDTSB,
            selectedAmountA,
            selectedAmountB,
            datasourceA,
            datasourceB,
            datasourceAName,
            datasourceBName,
            fromDatasourceId: from,
            toDatasourceId: to,
            dataSources: {
              datasourceA: manualMatchingData?.datasourceA,
              datasourceB: manualMatchingData?.datasourceB,
              datasourceAName: manualMatchingData?.datasourceAName,
              datasourceBName: manualMatchingData?.datasourceBName,
            },
            loader: false,
          },
          () => {
            this.createTables();
          }
        );
      }
    } catch (error) {
      this.setState({ loader: false });
      console.log("getManualMatchingData error", error);
    }
  }

  manualReconAccounts = async () => {
    try {
      const uniqueSelection = [
        ...new Set([
          ...this.state.selectedRowsDTSA,
          ...this.state.selectedRowsDTSB,
        ]),
      ];
      const reconAccountIds = uniqueSelection.map(
        (index) => this.state.transactionData[index]?.reconAccountId
      );
      const uniqueReconAccountIds = [...new Set(reconAccountIds)];
      const response = await axiosInstance.get(
        `/accounts/manual-recon-accounts-v2?batch_id=${
          this.state.transactionData && this.state.transactionData[0]?.batchId
        }&recon_account_ids=${
          uniqueReconAccountIds && uniqueReconAccountIds?.join(",")
        }`
      );

      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
      } else {
        if (response?.data) {
          this.populateOptions(response?.data);
        }
      }
    } catch (err) {
      Toast(err.response.data.message.description, "error");
    }
  };

  populateOptions = (data) => {
    // Extracting data from the API response
    const { categories, debitAccounts, creditAccounts } = data;

    // Transforming data for Select components
    const transformedCategories = categories?.map((item) => ({
      value: item.id,
      label: item.categoryName,
    }));

    const transformedDebitAccounts = debitAccounts?.map((item) => ({
      value: item.reconAccountId,
      label: item.name,
    }));

    const transformedCreditAccounts = creditAccounts?.map((item) => ({
      value: item.reconAccountId,
      label: item.name,
    }));

    // Set state variables
    this.setState({
      categories: transformedCategories,
      debitAccounts: transformedDebitAccounts,
      creditAccounts: transformedCreditAccounts,
    });
  };

  async getFilters() {
    try {
      const response = await axiosInstance.get(
        `/hierarchical/filters?screen_id=manual_matching`
      );
      if (response.message.status !== "200") {
        Toast(response.message.description, "error");
        this.setState({ filterLoader: false });
        this.getManualMatchingData();
      } else {
        let obj = mapFilters(response.data.filters);
        const { matchingFilter } = queryString.parse(
          this.props.location.search
        );
        if (matchingFilter) {
          obj.matching_singleselection.filter = [matchingFilter];
          obj.matching_singleselection.data.map((item) => {
            if (item.codeValue === matchingFilter) {
              item.param1 = "1";
            } else {
              item.param1 = "0";
            }
          });
        }
        this.setState(
          {
            filterData: response.data.filters,
            filters: obj,
            filterLoader: false,
          },
          () => {
            this.getManualMatchingData();
          }
        );
      }
    } catch (e) {
      console.log(e);
      this.getManualMatchingData();
      this.setState({ filterLoader: false });
    }
  }

  formatAccounts(accountList) {
    let accounts = accountList.accounts.map((account) => {
      return {
        value: account.id,
        label: account.name,
      };
    });
    return accounts;
  }

  updateSelection = (data, selected) => {
    const selectedData = selected
      ? selected
      : data.find((item) => item.selectDefault === true) || {};
    this.setState({
      adjustmentCategory: data.categories?.map((item) => ({
        value: item.id,
        label: item.categoryName,
      })),
      selectedCategory: selectedData.categoryId,
      debitAccounts:
        selectedData.debitAccounts?.map(({ reconAccountId, name }) => ({
          value: reconAccountId,
          label: name,
        })) || [],
      creditAccounts:
        selectedData.creditAccounts?.map(({ reconAccountId, name }) => ({
          value: reconAccountId,
          label: name,
        })) || [],
      selectDebitAccount:
        selectedData.debitAccounts?.find(
          (instance) => instance.selectDefault === true
        )?.reconAccountId || null,
      selectCreditAccount:
        selectedData.creditAccounts?.find(
          (instance) => instance.selectDefault === true
        )?.reconAccountId || null,
    });
  };

  addNote() {
    let now = moment();
    let comment = document.getElementById("note").value;
    if (comment !== "") {
      let note = {
        comment: comment,
        author: this.state.userName,
        date: now.format(this.props?.dateTime?.date),
        time: now.format(this.props?.dateTime?.time),
      };
      let _note = this.state.notes;
      _note.push(note);
      document.getElementById("note").value = "";
      this.setState({ notes: _note, noteFlag: true });
    }
  }

  async manualMatch() {
    try {
      const {
        datasourceA,
        datasourceB,
        datasourceAName,
        datasourceBName,
        notes,
        useCaseImplied,
        transactionData,
        selectedRowsDTSA,
        selectedRowsDTSB,
        selectedDataSourceName,
        userId,
        filters,
        isSelectedRowsDTSA,
        isSelectedRowsDTSB,
        fromDatasourceId,
        toDatasourceId,
        selectedCategory,
        selectedCreditAccount,
        selectedDebitAccount,
      } = this.state;

      let selectedMatching = filters?.matching_singleselection?.filter.join("");

      let selectedDatasources = transactionData.filter((transaction, index) => {
        if (isSelectedRowsDTSA) {
          return selectedRowsDTSA.includes(index);
        } else if (isSelectedRowsDTSB) {
          return selectedRowsDTSB.includes(index);
        } else {
          return [];
        }
      });
      let manualMatch = selectedDatasources.map((transaction) => {
        return {
          matchingData: JSON.stringify(transaction.matchingData),
          id: transaction.id,
          batchId: transaction?.batchId,
        };
      });

      let payload = {
        matchFilterApplied: parseInt(selectedMatching),
        raisedBy: parseInt(userId),
        notes: notes,
        useCaseApplied: useCaseImplied,
        custom_field_1: datasourceA,
        custom_field_2: datasourceB,
        customData:
          this.state.useCaseImplied === "impacting"
            ? JSON.stringify(this.state.adjustmentEntries)
            : null,
        dsA: datasourceAName,
        dsB: datasourceBName,
        selected_ds: parseInt(
          isSelectedRowsDTSA ? fromDatasourceId : toDatasourceId
        ),
        selected_ds_name: selectedDataSourceName,
        adjustmentCategory:
          useCaseImplied === "impacting" ? selectedCategory : null,
        manualMatchTransactions: manualMatch,
        accountDetails:
          useCaseImplied === "impacting"
            ? {
                creditAccount: selectedCreditAccount,
                debitAccount: selectedDebitAccount,
              }
            : null,
      };
      if (this.state.notes.length < 1 || this.state.noteFlag === false) {
        //In Modal Move to Top for Showing Notes Section
        document
          .getElementsByClassName("modal")?.[0]
          .scrollTo({ top: 0, left: 0, behavior: "smooth" });
        Toast("Please add note", "error");
        return;
      } else if (
        useCaseImplied === "impacting" &&
        (selectedCategory === "" ||
          selectedCategory === null ||
          selectedCategory === undefined)
      ) {
        Toast("Please select adjustment category", "error");
        return;
      } else if (
        useCaseImplied === "impacting" &&
        (selectedDebitAccount === "" ||
          selectedDebitAccount === null ||
          selectedDebitAccount === undefined)
      ) {
        Toast("Please select debit account", "error");
        return;
      } else if (
        useCaseImplied === "impacting" &&
        (selectedCreditAccount === "" ||
          selectedCreditAccount === null ||
          selectedCreditAccount === undefined)
      ) {
        Toast("Please select credit account", "error");
        return;
      } else {
        let response = await axiosInstance.post("/matching", payload);
        if (response.message.status === "200") {
          Toast(response.message.description, "success");
          this.setState({
            isSelectedRowsDTSA: false,
            isSelectedRowsDTSB: false,
            selectedRowsDTSA: [],
            selectedRowsDTSB: [],
            selectedAmountA: 0,
            selectedAmountB: 0,
            selectedCategory: "",
            selectedCreditAccount: "",
            selectedDebitAccount: "",
            notes: [],
            useCaseImplied: "",
            transactionData: [],
            datasourceA: "",
            datasourceB: "",
            filters: [],
            selectedMatching: "",
            selectedDatasources: [],
            manualMatch: [],
            manualMatchTransactions: [],
            accountDetails: {
              creditAccount: "",
              debitAccount: "",
            },
            loader: true,
            filterLoader: true,
            matchModalShow: false,
            noteFlag: false,
            isProcessing: false,
          });
        } else {
          this.setState({ isProcessing: false });
          Toast(response.message.description, "error");
        }
      }
      this.componentDidMount();
    } catch (error) {
      this.setState({ isProcessing: false });
      Toast(error.response.data.message.description, "error");
    }
  }

  handleOnSelectDTS = (row, isSelect) => {
    let {
      selectedAmountA,
      selectedAmountB,
      amountsDTSA,
      amountsDTSB,
      data1,
      data2,
    } = this.state;
    if (isSelect) {
      selectedAmountA += parseInt(amountsDTSA[row.id] ?? 0);
      selectedAmountB += parseInt(amountsDTSB[row.id] ?? 0);
      this.setState(() => ({
        selectedRowsDTSA:
          data1.length > 0 ? [...this.state.selectedRowsDTSA, row.id] : [],
        selectedRowsDTSB:
          data2.length > 0 ? [...this.state.selectedRowsDTSB, row.id] : [],
        selectedRows: [...this.state.selectedRows, row.id],
        selectedAmountA: selectedAmountA,
        selectedAmountB: selectedAmountB,
      }));
    } else {
      selectedAmountA -= parseInt(amountsDTSA[row.id] ?? 0);
      selectedAmountB -= parseInt(amountsDTSB[row.id] ?? 0);
      this.setState(() => ({
        selectedRows: this.state.selectedRows.filter((x) => x !== row.id),
        selectedRowsDTSA: this.state.selectedRowsDTSA.filter(
          (x) => x !== row.id
        ),
        selectedRowsDTSB: this.state.selectedRowsDTSB.filter(
          (x) => x !== row.id
        ),
        selectedAmountA: selectedAmountA,
        selectedAmountB: selectedAmountB,
      }));
    }
  };

  handleOnSelectAllDTS = (isSelect, rows) => {
    let {
      selectedAmountA,
      selectedAmountB,
      amountsDTSA,
      amountsDTSB,
      data1,
      data2,
    } = this.state;
    let ids = [];
    // eslint-disable-next-line
    rows.map((r) => {
      ids.push(r.id);
    });
    amountsDTSA.map((amount) => {
      return (selectedAmountA += parseInt(amount ?? 0));
    });
    amountsDTSB.map((amount) => {
      return (selectedAmountB += parseInt(amount ?? 0));
    });
    if (isSelect) {
      this.setState(() => ({
        selectedRows: ids,
        selectedRowsDTSA: data1.length > 0 ? ids : [],
        selectedRowsDTSB: data2.length > 0 ? ids : [],
        selectedAmountA: selectedAmountA,
        selectedAmountB: selectedAmountB,
      }));
    } else {
      this.setState(() => ({
        selectedRows: [],
        selectedRowsDTSA: [],
        selectedRowsDTSB: [],
        selectedAmountA: 0,
        selectedAmountB: 0,
      }));
    }
  };

  setFilter = (filters) => {
    this.setState({ filters });
  };

  handleRowClick = (e, row) => {
    this.setState({
      ...this.state,
      showTLModal: true,
      aggregateTxnId: row?.aggregateTxnId,
    });
  };

  render() {
    const {
      manualMatchingData,
      notes,
      filters,
      filterData,
      filterLoader,
      loader,
      column1,
      column2,
      column1Adjustment,
      column2Adjustment,
      data1,
      data2,
      transactionData,
      selectedRows,
      selectedCategory,
      selectedDebitAccount,
      selectedCreditAccount,
      categories,
      debitAccounts,
      creditAccounts,
    } = this.state;
    const { txnId, matchingFilter } = queryString.parse(
      this.props.location.search
    );

    const selectRowDTSA = {
      mode: "checkbox",
      // clickToSelect: true,
      selected: this.state.selectedRows,
      onSelect: this.handleOnSelectDTS,
      onSelectAll: this.state.data1.length > 0 && this.handleOnSelectAllDTS,
    };

    const selectRowDTSB = {
      mode: "checkbox",
      // clickToSelect: true,
      selected: this.state.selectedRows,
      onSelect: this.handleOnSelectDTS,
      onSelectAll: this.state.data2.length > 0 && this.handleOnSelectAllDTS,
    };
    const allow = this.state.selectedRows.length > 0;
    const { setPresistState } = this.context;
    return (
      <TransactionsStyle>
        {(filterLoader || loader) && (
          <div
            className="spinner-center"
            style={{ top: "0%", left: "0%", zIndex: 2000 }}
          >
            <LoaderComponent />
          </div>
        )}
        <div
          style={{
            opacity: !filterLoader && !loader ? "1" : "0.07",
          }}
        >
          <div>
            <div className="row">
              <div className="col-md-6 col-lg-12">
                <Breadcrumbs
                  data={
                    txnId && matchingFilter
                      ? this.state.breadCrumbState2
                      : this.state.breadCrumbState
                  }
                  history={this.props.history}
                  onClick={() => {
                    // if user redirect back to transaction log from breadcrumbs then return route true to tell the tlog screen it redirected back from frontend side
                    if (txnId && matchingFilter) {
                      setPresistState((prevState) => {
                        return {
                          ...prevState,
                          route: prevState ? true : false,
                        };
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className="row mb-4 align-items-center d-lg-flex flex-wrap">
              {filterData.map(({ filterName, filterType, filterLabel }) => (
                <div
                  className="filter-item col-sm-6 col-lg-3 mt-2"
                  style={{ minWidth: "210px" }}
                >
                  <Filter
                    title={filterLabel}
                    type={filterType}
                    data={filters[filterName]?.data}
                    filter={filters[filterName]?.filter}
                    onChange={(e) =>
                      handleFilterChange(e, filterName, filters, this.setFilter)
                    }
                    isSearchable={filterName === "response_code" ? true : false}
                  />
                </div>
              ))}
              <div className="col-md-12 col-lg-4 col-xl-2 align-items-center justify-content-start d-flex mt-2 mb-2">
                <ButtonBasic
                  icon={FilterIcon}
                  title="Filter"
                  onClick={() =>
                    this.setState({ loader: true }, () =>
                      this.getManualMatchingData()
                    )
                  }
                />
              </div>
              <div className="col-lg-12 mt-lg-3 col-xl-4">
                <div className="d-flex justify-content-end h-100 align-items-center">
                  <ButtonBasic
                    classes={`${
                      // eslint-disable-next-line
                      this.state?.filters?.matching?.filter[0] == 140002
                        ? allow
                          ? ""
                          : "btn-disabled"
                        : this.state.selectedRows.length > 0
                        ? ""
                        : "btn-disabled"
                    }`}
                    title={
                      // eslint-disable-next-line
                      this.state?.filters?.matching?.filter[0] == 140002
                        ? "Resolve Transactions"
                        : "Copy Transactions"
                    }
                    icon={ArrowRightIcon}
                    onClick={() => {
                      let rowsNotes = transactionData.filter(
                        (transaction) =>
                          selectedRows.includes(transaction.index) &&
                          transaction.note !== null &&
                          transaction.note
                      );
                      if (
                        // eslint-disable-next-line
                        this.state?.filters?.matching?.filter[0] == 140002
                          ? allow
                          : this.state.selectedRows.length > 0
                      ) {
                        this.setState({
                          matchModalShow: true,
                          useCaseImplied: "non-impacting",
                          notes: rowsNotes[0]?.note ?? [],
                        });
                      } else {
                        Toast(
                          "Please select atleast one transaction!",
                          "error"
                        );
                      }
                    }}
                  />
                  <ButtonBasic
                    classes={`${
                      allow && this.state?.allowAccountEntries
                        ? ""
                        : "btn-disabled"
                    }`}
                    title="Match With Adjustment"
                    onClick={() => {
                      let rowsNotes = transactionData.filter(
                        (transaction) =>
                          selectedRows.includes(transaction.index) &&
                          transaction.note !== null &&
                          transaction.note
                      );
                      if (allow && this.state?.allowAccountEntries) {
                        this.setState({
                          matchModalShow: true,
                          useCaseImplied: "impacting",
                          notes: rowsNotes[0]?.note ?? [],
                        });
                        this.manualReconAccounts();
                      } else {
                        Toast("Please select atleast one transaction", "error");
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="d-flex mt-2 overflow-y-auto fancy-scroll">
                  <CardSm
                    classes="mr-4"
                    title="total transactions"
                    value={this.pagination.totalSize}
                  />
                  <CardSm
                    classes="mr-4"
                    title="selected transactions"
                    value={this.state.selectedRowsDTSA.length}
                  />
                  <CardSm
                    title="Selected Amount"
                    value={
                      <CurrencyFormat
                        value={this.state?.selectedAmountA ?? "-"}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={this.props?.dateTime?.currency + " "}
                      />
                    }
                  />
                </div>
                <div className="mt-4">
                  <div className="d-flex justify-content-between align-items-center h-100">
                    <div className="color-lightBlue">
                      {manualMatchingData?.datasourceA}
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="overflow-y-auto fancy-scroll">
                    {column1?.length >= 1 ? (
                      <BootstrapTable
                        bordered={false}
                        classes="rs-table table-layout-auto table-custom-style"
                        keyField="id"
                        data={data1}
                        columns={column1}
                        selectRow={selectRowDTSA}
                        rowEvents={{ onClick: this.handleRowClick }}
                        noDataIndication="No data found!"
                      ></BootstrapTable>
                    ) : (
                      <p align="center">No data found!</p>
                    )}
                    <span>
                      <button
                        className="btn btn-default btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {this.pagination.sizePerPage}
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <span
                          className="dropdown-item"
                          onClick={() => this.onSizePerPageChange(15)}
                        >
                          15
                        </span>
                        <span
                          className="dropdown-item"
                          onClick={() => this.onSizePerPageChange(30)}
                        >
                          30
                        </span>
                        <span
                          className="dropdown-item"
                          onClick={() => this.onSizePerPageChange(50)}
                        >
                          50
                        </span>
                        <span
                          className="dropdown-item"
                          onClick={() => this.onSizePerPageChange(100)}
                        >
                          100
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="d-flex mt-2 overflow-y-auto fancy-scroll">
                  <CardSm
                    classes="mr-4"
                    title="total transactions"
                    value={this.pagination.totalSize}
                  />
                  <CardSm
                    classes="mr-4"
                    title="selected transactions"
                    value={this.state.selectedRowsDTSB.length}
                  />
                  <CardSm
                    title="Selected Amount"
                    value={
                      <CurrencyFormat
                        value={this.state?.selectedAmountB}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={this.props?.dateTime?.currency + " "}
                      />
                    }
                  />
                </div>
                <div className="mt-4">
                  <div className="d-flex justify-content-between align-items-center h-100">
                    <div className="color-success">
                      {manualMatchingData?.datasourceB}
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <div className="overflow-y-auto fancy-scroll">
                    {column2?.length >= 1 ? (
                      <BootstrapTable
                        bordered={false}
                        classes="rs-table table-layout-auto table-custom-style"
                        keyField="id"
                        data={data2}
                        columns={column2}
                        selectRow={selectRowDTSB}
                        remote={{ pagination: true }}
                        pagination={paginationFactory(this.pagination)}
                        noDataIndication="No data found!"
                      ></BootstrapTable>
                    ) : (
                      <p align="center">No data found!</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <TransactionlogModal
              show={this.state.showTLModal}
              onHide={() =>
                this.setState({ ...this.state, showTLModal: false })
              }
              transactionId={this.state.aggregateTxnId}
              setShowModal={this.state.showTLModal}
            ></TransactionlogModal>

            <div className="col-md-12">
              <Modal
                backdrop={true}
                size="xl"
                show={this.state.matchModalShow}
                onHide={() => {
                  this.setState({
                    notes: [],
                    selectedCategory: "",
                    selectedCreditAccount: "",
                    selectedDebitAccount: "",
                    matchModalShow: false,
                    useCaseImplied: "",
                    isSelectedRowsDTSA: false,
                    isSelectedRowsDTSB: false,
                    adjustmentEntries: [],
                  });
                  this.updateSelection(this?.state?.apiData);
                }}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header
                  closeButton
                  onHide={() => {
                    this.setState({
                      notes: [],
                      selectedCategory: "",
                      selectedCreditAccount: "",
                      selectedDebitAccount: "",
                      matchModalShow: false,
                      useCaseImplied: "",
                      isSelectedRowsDTSA: false,
                      isSelectedRowsDTSB: false,
                      adjustmentEntries: [],
                    });
                    this.updateSelection(this?.state?.apiData);
                  }}
                >
                  {this.state.useCaseImplied === "impacting"
                    ? "Match With Adjustment"
                    : "Copy Transaction"}
                </Modal.Header>
                <Modal.Body scrollable="true">
                  <div className="row">
                    <div className="col-lg-8 col-12">
                      {this.state.useCaseImplied === "impacting" && (
                        <div className="row">
                          <div className="col-4">
                            <label style={{ fontSize: "14px" }}>
                              Adjustment Category*
                            </label>
                            <Select
                              placeholder="Select Category"
                              options={categories}
                              value={categories.find(
                                (item) => item.value === selectedCategory
                              )}
                              onChange={(e) =>
                                this.setState({
                                  ...this.state,
                                  selectedCategory: e.value,
                                })
                              }
                              isSearchable
                              className="no-border mt-2"
                            />
                          </div>

                          <div className="col-4">
                            <label style={{ fontSize: "14px" }}>
                              Debit Account*
                            </label>
                            <Select
                              placeholder="Debit Account"
                              isDisabled={
                                debitAccounts.length > 0 ? false : true
                              }
                              options={debitAccounts}
                              value={debitAccounts.find(
                                (item) => item.value === selectedDebitAccount
                              )}
                              onChange={(e) =>
                                this.setState({
                                  ...this.state,
                                  selectedDebitAccount: e.value,
                                })
                              }
                              isSearchable
                              className="no-border mt-2"
                            />
                          </div>
                          <div className="col-4">
                            <label style={{ fontSize: "14px" }}>
                              Credit Account*
                            </label>
                            <Select
                              placeholder="Credit Account"
                              isDisabled={
                                creditAccounts.length > 0 ? false : true
                              }
                              options={creditAccounts}
                              value={creditAccounts.find(
                                (item) => item.value === selectedCreditAccount
                              )}
                              onChange={(e) =>
                                this.setState({
                                  ...this.state,
                                  selectedCreditAccount: e.value,
                                })
                              }
                              isSearchable
                              className="no-border mt-2"
                            />
                          </div>
                        </div>
                      )}
                      <div className="row mt-3">
                        <div className="col-sm-12">
                          <div style={{ minHeight: "18em" }}>
                            <div className="color-danger">
                              <div className="color-lightBlue">
                                {manualMatchingData?.datasourceA}
                              </div>
                              <div>
                                {column1Adjustment?.length >= 1 && (
                                  <BootstrapTable
                                    bordered={this.state.isSelectedRowsDTSA}
                                    classes={`rs-table table-layout-auto table-custom-style ${
                                      this.state.isSelectedRowsDTSA
                                        ? "mb-0"
                                        : ""
                                    } ${
                                      this.state.isSelectedRowsDTSA
                                        ? "matching-box-shadow"
                                        : ""
                                    }`}
                                    keyField="id"
                                    data={
                                      this.state.isSelectedRowsDTSA &&
                                      this.state?.adjustmentEntries?.length > 0
                                        ? data1.filter((data) =>
                                            this.state.selectedRowsDTSA.includes(
                                              data?.id
                                            )
                                          )
                                        : data1.filter((data) =>
                                            this.state.selectedRowsDTSA.includes(
                                              data?.id
                                            )
                                          )
                                    }
                                    columns={column1Adjustment}
                                    rowEvents={{
                                      onClick: (e, row, rowIndex) => {
                                        const dsA = data1.filter((data) =>
                                          this.state.selectedRowsDTSA.includes(
                                            data?.id
                                          )
                                        );
                                        let flag = false;

                                        for (const obj of dsA) {
                                          let hasNonNullValue = false;

                                          for (const [
                                            key,
                                            value,
                                          ] of Object.entries(obj)) {
                                            if (
                                              key !== "id" &&
                                              key !== "tId" &&
                                              key !== "aggregateTxnId"
                                            ) {
                                              if (value !== null) {
                                                hasNonNullValue = true;
                                                break;
                                              }
                                            }
                                          }

                                          if (hasNonNullValue) {
                                            flag = true;
                                            break;
                                          }
                                        }
                                        if (dsA?.length > 0 && flag) {
                                          this.setState({
                                            isSelectedRowsDTSA:
                                              !this.state.isSelectedRowsDTSA,
                                            isSelectedRowsDTSB: false,
                                            selectedDataSourceName:
                                              manualMatchingData?.datasourceAName,
                                            adjustmentEntries: [],
                                          });
                                        }
                                      },
                                    }}
                                    noDataIndication="No data found!"
                                    wrapperClasses="overflow-y-auto fancy-scroll"
                                    pagination={paginationFactory(
                                      this.modalPagination
                                    )}
                                  ></BootstrapTable>
                                )}
                              </div>
                              <div className="mt-4 color-success">
                                {manualMatchingData?.datasourceB}
                              </div>
                              <div>
                                {column2Adjustment?.length >= 1 && (
                                  <BootstrapTable
                                    bordered={this.state.isSelectedRowsDTSB}
                                    classes={`rs-table table-layout-auto table-custom-style ${
                                      this.state.isSelectedRowsDTSB
                                        ? "mb-0"
                                        : ""
                                    } ${
                                      this.state.isSelectedRowsDTSB
                                        ? "matching-box-shadow"
                                        : ""
                                    }`}
                                    keyField="id"
                                    data={
                                      this.state.isSelectedRowsDTSB &&
                                      this.state?.adjustmentEntries?.length > 0
                                        ? data2.filter((data) =>
                                            this.state.selectedRowsDTSB.includes(
                                              data?.id
                                            )
                                          )
                                        : data2.filter((data) =>
                                            this.state.selectedRowsDTSB.includes(
                                              data?.id
                                            )
                                          )
                                    }
                                    columns={column2Adjustment}
                                    rowEvents={{
                                      onClick: (e, row, rowIndex) => {
                                        const dsB = data2.filter((data) =>
                                          this.state.selectedRowsDTSB.includes(
                                            data?.id
                                          )
                                        );
                                        let flag = false;
                                        for (const obj of dsB) {
                                          let hasNonNullValue = false;

                                          for (const [
                                            key,
                                            value,
                                          ] of Object.entries(obj)) {
                                            if (
                                              key !== "id" &&
                                              key !== "tId" &&
                                              key !== "aggregateTxnId"
                                            ) {
                                              if (value !== null) {
                                                hasNonNullValue = true;
                                                break;
                                              }
                                            }
                                          }

                                          if (hasNonNullValue) {
                                            flag = true;
                                            break;
                                          }
                                        }
                                        if (dsB?.length > 0 && flag) {
                                          this.setState({
                                            isSelectedRowsDTSB:
                                              !this.state.isSelectedRowsDTSB,
                                            isSelectedRowsDTSA: false,
                                            selectedDataSourceName:
                                              manualMatchingData?.datasourceBName,
                                            adjustmentEntries: [],
                                          });
                                        }
                                      },
                                    }}
                                    noDataIndication="No data found!"
                                    wrapperClasses="overflow-y-auto fancy-scroll"
                                    pagination={paginationFactory(
                                      this.modalPagination
                                    )}
                                  ></BootstrapTable>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.showNotes && (
                      <div className="col-4">
                        <div className="notes-wrapper">
                          <h5 className="mb-3 color-gray-dim">Notes</h5>
                          <div
                            className="overflow-auto fancy-scroll"
                            style={{
                              height: "25em",
                            }}
                          >
                            <div
                              className="mt-3 position-relative"
                              style={{
                                minHeight: "95%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-end",
                              }}
                            >
                              <div className="mt-2">
                                {notes?.map(
                                  ({ author, comment, date, time }, i) =>
                                    comment && (
                                      <ul
                                        className="rs-notes-container"
                                        key={i}
                                      >
                                        <li className="rs-notes-item">
                                          <h6>{author}</h6>
                                          <div className="note-comment">
                                            {comment}
                                          </div>
                                          <sub>
                                            {date} {time}
                                          </sub>
                                        </li>
                                      </ul>
                                    )
                                )}
                              </div>
                              <textarea
                                placeholder="Write a Note"
                                rows="3"
                                id="note"
                                className="textArea"
                              ></textarea>
                              <div className="w-100 mt-2">
                                <ButtonBasic
                                  classes={"primary px-4 w-100"}
                                  title={"Add Notes"}
                                  onClick={() => this.addNote()}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.useCaseImplied === "impacting" &&
                      this.state.adjustmentEntries.length > 0 && (
                        <div className="row mt-4 w-100">
                          <div className="col-12 ml-3">
                            <div className="mt-4 color-success mb-2">
                              Manual Adjustment Entries
                            </div>
                            <BootstrapTable
                              keyField="id"
                              data={this.state.adjustmentEntries}
                              columns={this.state.adjustmentEntriesColumns}
                              bordered={false}
                              classes="rs-table table-layout-auto table-custom-style"
                              headerClasses="d-none"
                              noDataIndication="No data found!"
                              wrapperClasses="overflow-y-auto fancy-scroll"
                              pagination={paginationFactory(
                                this.modalAdjustmentPagination
                              )}
                            ></BootstrapTable>
                          </div>
                        </div>
                      )}
                    <div className="row mt-2 ml-1">
                      <div className="col-12">
                        {this.state.useCaseImplied === "impacting" ? (
                          <div className="alert alert-danger" role="alert">
                            These changes will only be committed once approved.
                          </div>
                        ) : (
                          <div className="alert alert-danger" role="alert">
                            These transactions will not generate any financial
                            impact and will only be marked as resolved in the
                            system
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex">
                    <ButtonBasic
                      title={this.state.showNotes ? "Hide Notes" : "Show Notes"}
                      onClick={() => {
                        this.setState({
                          showNotes: !this.state.showNotes,
                        });
                        //In Modal Move to Top for Showing Notes Section
                        document
                          .getElementsByClassName("modal")?.[0]
                          .scrollTo({ top: 0, left: 0, behavior: "smooth" });
                      }}
                    />
                    <ButtonBasic
                      title="Cancel"
                      onClick={() => {
                        this.setState({
                          notes: [],
                          selectedCategory: "",
                          selectedCreditAccount: "",
                          selectedDebitAccount: "",
                          matchModalShow: false,
                          useCaseImplied: "",
                          isSelectedRowsDTSA: false,
                          isSelectedRowsDTSB: false,
                          adjustmentEntries: [],
                        });
                        this.updateSelection(this?.state?.apiData);
                      }}
                    />

                    {/* code for Match with adjustment */}
                    {this.state.useCaseImplied === "impacting" && (
                      <CustomTooltip
                        placement="top"
                        tooltipId="button-tooltip-2"
                        disabled={
                          this.state.selectedCategory &&
                          this.state.selectedCreditAccount &&
                          this.state.selectedDebitAccount &&
                          this.state?.adjustmentEntries?.length > 0 &&
                          this.state.notes?.length > 0
                            ? false
                            : true
                        }
                        disabledMessage={
                          "Select a data source, choose the adjustment category for credit or debit accounts, and add any notes"
                        }
                        onClick={() => {
                          if (
                            this.state.isSelectedRowsDTSA ||
                            this.state.isSelectedRowsDTSB
                          ) {
                            if (this.state.adjustmentEntries.length > 0) {
                              if (this.state.isProcessing === false) {
                                this.setState({ isProcessing: true });
                                this.manualMatch();
                              }
                            } else {
                              Toast("Adjustment Entries!", "error");
                            }
                          } else {
                            Toast("Select One Datasource!", "error");
                          }
                        }}
                        buttonText="Done"
                      >
                        {this.state.adjustmentEntries.length > 0
                          ? "Done"
                          : "Show Adjustment Entries"}
                      </CustomTooltip>
                    )}

                    {/* code for copy transactions */}
                    {this.state.useCaseImplied === "non-impacting" && (
                      <CustomTooltip
                        placement="top"
                        tooltipId="button-tooltip-2"
                        disabled={
                          this.state?.notes?.length > 0 &&
                          (this.state.isSelectedRowsDTSA ||
                            this.state.isSelectedRowsDTSB)
                            ? false
                            : true
                        }
                        disabledMessage={"Select any Data Source and add Notes"}
                        // onClick={this.handleShowAdjustment}
                        onClick={() => {
                          if (
                            this.state.isSelectedRowsDTSA ||
                            this.state.isSelectedRowsDTSB
                          ) {
                            if (this.state.isProcessing === false) {
                              this.setState({
                                isSelectedRowsDTSA: false,
                                isSelectedRowsDTSB: false,
                                isProcessing: true,
                              });
                              this.manualMatch();
                            }
                          } else {
                            Toast("Select One Datasource!", "error");
                          }
                        }}
                        buttonText="Done"
                      >
                        {this.state.notes.length > 0 &&
                        (this.state.isSelectedRowsDTSA ||
                          this.state.isSelectedRowsDTSB)
                          ? "Done"
                          : "Select any Data Source and add Notes"}
                      </CustomTooltip>
                    )}
                  </div>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </TransactionsStyle>
    );
  }
}

export default withDateTime(Matching);
